.dashboard {
    width: 100%;
}
.dashboard h2 {
    margin-top: 20px;
}
.notebook-grid{
    margin:20px auto;
    margin-top: 10px;
    display: grid;
    padding: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    gap: 10px;
}
.notebook-grid .page-title {
    margin-top: 10px;
}
.notebook-list {
    margin: auto;
    width: 85%;
    margin-top: 25px;
    /* background-color: white; */
    background-color: rgb(249, 248, 245);
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.notebook-list .btns {
    margin-right: auto;
}
.notebook-list .page-title {
    width: auto;
}
.notebook-list h6, .notebook-list h5{
    text-align: center;
}
.notebook-list-item {
    padding: 5px;
    display: grid;
    grid-template-columns: .5fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #ddd;
    min-height: 40px;
}
.notebook-list-item .gridCell{
    position:relative;
}
.notebook-list-item .gridItem{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.notebook-grid-item{
    max-width: 600px;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    background-color: rgb(249, 248, 245);
    /* box-shadow: 3px 3px 5px rgba(0,0,0,0.05); */
}
.notebook-grid-item img, .notebook-list-item img{
    width: 23px;
    height: 23px;
    float: right;
}

.notebook-grid-item h6, .notebook-grid-item h5, .notebook-list-item h6, .notebook-list-item h5, .date {
    color: rgba(0,0,0,0.4);
    margin:0;
}
.date{
    font-size: small;
}
.notebook-grid-item h6, .notebook-list-item h6{
    font-weight: 700;
}
.notebook-grid-item img:hover, .notebook-list-item img:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    cursor:pointer;
}

@media (max-width: 1600px){
    .notebook-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1200px){
    .notebook-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .notebook-list {
        margin: 15px;
        width: auto;
    }
}
@media (max-width: 540px){
    .notebook-grid {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 415px){
    .notebook-grid{
        padding-left: 10px;
        padding-right: 10px;
        /* grid-template-columns: 1fr; */
    }
    .notebook-list {
        margin-left: 10px;
        margin-right: 10px;
    }
    .notebook-grid-item{
        /* height: 100px; */
    }
    .author, .gridDesc{
        display: none;
    }
    .notebook-list-item{
        grid-template-columns: .4fr 1fr 0fr 0fr 1fr;
    }
}