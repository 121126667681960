
.auth-form {
    max-width: 360px;
    margin: auto;
    margin-top: 50px;
    padding: 40px;
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    background: #fff;
    margin-bottom: 300px;
  }
.auth-form .btn {
    width: 100%
}
.auth-form input {
    width: 347px;
}
.auth-container{
    width: 100%;
}
.auth-reset{
    margin-bottom: 400px;
}
.login-link {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 0px;
    color: var(--heading-color);
    font-size: small;
    cursor: pointer;
}
.auth-form .forgot {
    color: var(--blue-color)
}

@media (max-width: 415px){
    .auth-form {
        padding: 30px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .auth-form input {
        width: 300px;
    }
}