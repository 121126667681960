.text-nav{
    margin: 10px auto;
    width: 500px;
    justify-content: center;
    text-align: right;
    font-weight: 600;
}
.right-arr{
  transform: rotate(270deg);
}
.left-arr{
  transform: rotate(90deg);
}
.small-search-icon {
  display: none;
}
@media (max-width: 415px) {
    .text-nav{
        width: 360px;
    }
    .small-search-icon {
      display: block;
    }
    .list-toggle-icon {
      display: none;
    }
}