.sidebar {
  overflow: hidden;
  /* background: rgb(240,240,240); */
  /* background-color: rgb(139, 145, 225); */
  /* background-color: rgb(93, 93, 85); */
  /* border-right: 1px solid rgb(213, 213, 213); */
  background-color: white;
  min-height: calc(100vh - 53px);
  height: 100;
  box-sizing: border-box;
  /* border: 1px solid rgba(0,0,0,.2); */
  border-top: 0px solid rgba(0, 0, 0, 0.05);
  /* color: var(--bg-color); */
  color: var(--heading-color);
  box-sizing: border-box;
}
.thick-sidebar{
  min-width: 200px;
}
.slim-sidebar{
  min-width: 65px;
  max-width: 65px;
}
.sidebar-content {
  position: fixed;
  width: 200px;
}
.sidebar .user {
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 30px 0px;
  padding-bottom: 0;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}

.sidebar .links {
  height: 100%;
  /* margin-top: 45px; */
  padding-top: 20px;
  /* padding-left: 10px; */
  position: fixed;
  border-right: 1px solid rgb(213, 213, 213);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.thick-sidebar .links{
  width: 198px;
}

.slim-sidebar .links{
  width: 63px;
}
.slim-sidebar img{
  padding-bottom: 5px;
}
.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  /* font-size: small; */
  color: var(--heading-color);
  /* color: var(--bg-color); */
  box-sizing: border-box;
}
.sidebar .links img {
  width: 25px;
  height: 25px;
  padding-bottom: 5px;
  padding-left: 5px;
  /* filter: invert(40%); */
  /* filter: invert(100%); */
  filter: invert(30%);
  margin-right: 10px;
}
.sidebar .links a.active {
  /* color: #555; */
  /* background: var(--bg-color); */
  background: rgba(0,0,0,.065);
}
.sidebar .links .active img {
  /* filter: invert(40%); */
}

.sidebar .links a:hover {
    /* color: #555; */
    background: rgba(0,0,0,.065);
  }
.sidebar .links a:hover img {
    /* filter: invert(40%); */
  }

.collapse {
  border-top: 1px solid rgba(0,0,0,.2);
  cursor: pointer;
  position: fixed; 
  bottom: 0;
  padding: 5px;
  background-color: white;
  width: 188px;
  
}
.sidebar .collapse img{
  float: right;
  justify-content: center;
  filter: invert(40%);
  width: 40px;
  height: 40px;
}
.thick-sidebar .collapse img{
  padding-right: 15px;
}
.slim-sidebar .collapse img{
  transform: rotate(180deg);
}
.collapse:hover{
  background: rgba(0,0,0,.065);
}
#slideout {
  right: 0px;
  -webkit-animation: slide 0.2s forwards;
  animation: slide 0.2s forwards;
}
.expand{
  background-color: var(--bg-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: fixed;
  bottom: 0;
  padding-bottom:10px;
  padding-left: 5px;
  z-index: 20;
}
.expand img {
  width: 40px;
  filter: invert(50%);
  cursor: pointer;
  transform: rotate(180deg);
}
.expand img:hover{
  border-radius: 10px;
  background-color: rgba(0,0,0,.1);
}
.sidebar hr {
  background-color: rgba(0,0,0,.05);
  border: 1px solid rgba(0,0,0,.05);
  margin: 10px 0px;
}
#slideout{
  display: none;
  position: fixed;
  left: -200px;
}
.slim-sidebar span{
  display: none;
}

.slim-sidebar .collapse, .slim-sidebar .expand{
  width: 63px;
  padding:0;
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (max-width: 750px){
  .thick-sidebar, .expand {
    display: none; 
  }
}
@media (max-width: 750px){
  .slim-sidebar {
    display: none; 
  }
}