
.terminal, .query-terminal {
    width: 100%;
    margin: 15px auto;
    padding: 20px;
    border-radius: 2px;
    box-sizing: border-box;
  }
.terminal {
  /* background: rgb(41, 41, 38); */
  /* background-color: rgba(240,240,240); */
  /* color: rgb(201, 201, 193); */
  color: var(--heading-color);
  border: 1px solid rgb(181, 181, 181);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.query-terminal {
  border: 2px solid var(--heading-color);
  /* background-color: rgb(255, 255, 255); */
  color: var(--heading-color);
}
.matchWord {
  background-color: var(--yellow-color);
  padding: 2px;
  border-radius: 5px;
  color: var(--heading-color)
  /* color:white; */
}
.light-terminal {
    width: 100%;
    margin: 30px auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    color: var(--heading-color);
    border: 1px solid rgb(211, 211, 211);
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
}
.light-terminal .term-header{
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
}
.terminal-body{
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  gap: 10px;
}
.term-header{
  width: 95%;
  margin: auto;
}
.terminal-content{
  display: flex;
  justify-content: center;
  margin:auto;
}
.light-terminal{
  display: block;
}
.term-side{
  margin: auto 0px;
  height: 30px;
  font-weight: 700;
  align-items: center;
}
.right-arrow {
  cursor: pointer;
  transform: rotate(-90deg);
}
.left-arrow {
  cursor: pointer;
  transform: rotate(90deg);
}
.right-arrow:hover {
  /* background-color: var(--primary-color); */
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
}
.left-arrow:hover {
  /* background-color: var(--primary-color); */
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
}
.par-block {
  margin: 10px;
  margin-top:15px;
}

.term-header {
  display: flex;
}

.term-nav {
  display: flex;
  margin-left: auto;
}
.term-btn {
  padding: 2px;
  margin-left: 10px;
  text-align: center;
  cursor: pointer;
  /* background-color: rgb(240,240,240); */
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
  border-radius: 50px;
  line-height: 100%;
}

.term-btn img{
  filter: invert(30%);
  width: 27px;
}
.term-btn:hover{
  background-color: rgb(220,220,220)
}

.term-btn :hover {
  color: rgba(255,255,255);
}
.query-terminal .term-btn :hover {
  color: var(--accent-color);
}

.divider{
  width: 100%;
  border-color: rgba(255,255,255,.2);

}
.term-center{
  width: 100%;
}
.term-side .term-nav {
  vertical-align: top !important;
}
.indent{
  padding-left: 40px;
}

@media (max-width:415px) {
  .terminal{
    padding-left:10px;
    padding-right:10px;
  }
}