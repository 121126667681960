.alert {
    display: flex;
    position: fixed;
    /* top:100px; */
    margin:auto;
    right:0;
    left:0;
    top:105px;
    /* z-index: 10; */
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    width: 250px;
    z-index: 10;
    border-radius: 2px;
    text-align: center;
    /* padding: 3px; */
}
.invisible-alert{
    display: none;
}
.alert p {
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;

}
.success {
    background-color: rgb(162, 225, 160);
}
.warning {
    /* background-color: rgb(245, 255, 159); */
    background-color: rgb(255, 238, 160);
    /* border: 1px solid rgb(167, 172, 117); */
    color: rgb(128, 115, 56);
}
.alert-tag {
    width:10px;
    /* border: 1px solid red; */
}
.alert-icon{
    padding:3px;
    align-self: center;
    color: rgb(207, 186, 93);
}
.warning .alert-tag{
    position: relative;
    background-color: rgb(183, 168, 98);
    color: white;

}
.alert-tag p{
    position: fixed;
    top:50%;
    line-height: 100%;
    /* margin: auto; */
}