
.note textarea{
    border: 1px solid rgb(211, 211, 211);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    height: 60px;
    min-height: 0px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.05);
    font-weight: 600;
    resize: "none";
    max-width: 100%;
    min-width: 100%;
    overflow:hidden;
}
.note textarea:focus {
    outline: none !important;
    border: 3px solid var(--primary-color);
}

.cell .note{
    padding: 20px;
}
.editorContainer{
    background-color: rgb(240, 240, 240);
    padding:20px;
    border-radius: 5px;
}
.noteTray hr{
    background-color: white;
    color:white;
    border: .5px solid rgb(220, 220, 220);
    /* padding-bottom: 10px; */
}
.noteTray img{
    filter: invert(25%);
    width: 23px;
    padding:5px;
    cursor: pointer;
}
.noteTray img:hover{
    background-color: rgb(255, 255, 255);
    /* background-color: #ffdb4b; */
    border-radius: 5px;
    cursor: pointer;
}
@media (max-width:415px) {
    .cell .note{
        padding: 10px;
    }
}