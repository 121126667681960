.drop-modal .modal-content {
    text-align: left;
    max-height: 400px;
    overflow-y: scroll;
}
.drop-modal .modal-buttons  {
    padding-top: 10px;
    display: flex;
    width: 100%;
}
.drop-modal .btn{
    width: 100%;
}
.drop-modal label {
    /* line-height: 100%; */
    display: grid;
    gap:5px;
    grid-template-columns: 1fr 10fr;
    padding: 5px;
}
.drop-modal span{
    margin: 0;
    padding: 2px;
}
.drop-modal input{
    align-self: center;
    height: 18px;
    width: 18px;
}

.odd-row{
    background-color: rgb(240,240,240);
}