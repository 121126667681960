.top-sidebar ul{
  display: flex;
  justify-content: center;
}
.top-sidebar{
  display: none;
  background-color: white;
  z-index: 15;
  position: fixed;
  top:48px;
  left:0;
  right:0;
  border-bottom: 1px solid rgb(213, 213, 213);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.top-sidebar ul li{
  /* margin: auto; */
  padding: 7px;
}
.top-sidebar li:hover{
  background: rgba(0,0,0,.065);
}
.top-sidebar .active-link{
  background: rgba(0,0,0,.065);
}
.top-sidebar .links img {
  padding: 10px;
  /* padding-top: 5px; */
  padding-bottom: 0px;
  width: 25px;
  height: 25px;
  filter: invert(30%);
}

@media (max-width: 750px){
  .top-sidebar {
    display: block; 
  }
}

@media (max-width: 415px){
  .top-sidebar .links img{
    padding-left: 5px;
    padding-right: 5px;
  }
}
