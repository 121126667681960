.App {
  display: block;
  box-sizing: border-box;

}
.App .container {
  flex-grow: 1;
  padding: 0 60px;
}
.content {
  display: flex;
}

div #back {
  position: fixed;
  height: 100px;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  min-height: 100px;
  border-top-left-radius: 250% 260px;
  border-top-right-radius: 250% 260px;
  margin-left: -2em;
  padding-right: 2em;
  z-index: -10;
}
