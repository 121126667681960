.settings{
    padding-left: 10px;
    padding-right: 10px;
    margin: 20px auto;
    width: 50%;
    min-width: 300px;
    max-width: 500px;
}
.settings-title{
    margin-bottom: 10px;
}
.settings-content{
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 80px;
}
.settings .btn{
    width: 100%;
}
.settings-content .page-title img{
    width: 25px;
    margin-right: 5px;
}
.settings-form input{
    margin-bottom: 20px;
}
@media (max-width: 415px){
    .settings {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
    .settings-content{
      padding-left: 40px;
      padding-right: 40px;
    }
  }