.notebook-form{
    box-sizing: border-box;
    margin: auto;
    margin-top: 30px;
    width: 100%;
    padding: 20px;
    border-radius: 2px;
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    /* background: #fff; */
    /* background-color: var(--light-grey-color); */
}
.notebook-form .center-label{
    
}
.notebook-form form{
    /* display: flex; */
}
.cell .notebook-form{
    margin-top: 10px;
}
.search-page .notebook-form .btn {
    background-color: #a2a1e6;
    border-color: #a2a1e6;
    padding: 0px;
    color:white;
}
.search-page .notebook-form .btn img{
    filter: invert(100%);
}
.notebook-form .btn {
    border-radius: 5px;
}
.notebook-header h2{
    margin:auto;
    text-align: center;
}
.notebook-header .options select {
    width: 85px;
    height: 39px;
}
.notebook-header .verse-selection select {
    width: 75px;
}
.center-label {
    margin-top: 0px
}

@media (max-width:415px) {

}
