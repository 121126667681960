.notebook-buttons{
    display: flex;
    border: 1px solid rgb(210, 210, 210);
    border-top: 0px;
    /* width: 90%; */
    height:39px;
    margin: auto;
}
.top-buttons{
    padding-left: 40px;
    padding-right: 40px;
}
.note-btn-icons {
    box-sizing: border-box;
    display: flex;
    margin-left: auto;
    border: 1px solid black;
    /* border-top: 0px; */
}
.top-buttons .note-btn-icons{
    border-top: 0px;
}
.btn-icon:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    cursor: pointer;
}
.btn-icon, .add-icon{
    padding:5px;
    width: 27px;
    vertical-align: middle;
}

.note-btn-icons .prev{
    transform: rotate(90deg); 
}
.note-btn-icons .next{
    transform: rotate(-90deg);
}
.prev{
    transform: rotate(-90deg);
}

.pagenum{
    margin:auto;
}