
.navbar {
    position: fixed;
    top:0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    /* background-color: rgb(233, 233, 233); */
    background-color: rgb(41, 39, 47);
    z-index: 20;
}
.nav-content{
  margin:auto;
  padding-left: 15px;
  padding-right: 15px;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.navbar .logo {
  font-weight: bold;
  color: rgba(230,230,230);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.navbar .logo img {
  margin-right: 10px;
  filter: invert(90%);
  width: 36px;
  margin-top: -8px;
}

.navbar .search-icon {
  /* filter: invert(25%); */
  margin: auto;
}
.navbar .search-icon a {
  color: white;
  /* border: 2px solid var(--blue-color); */
  background-color: var(--blue-color);
  border-radius: 20px;
  padding: 3px 5px;
}
.navbar .search-icon a:hover {
  background-color: var(--blue-color);
  color: white;
  font-weight: 600;
}
.navbar a {
  color: rgba(200,200,200);
  text-decoration: none;
  margin-right: 20px;
}
.navbar a:hover {
  color: white;
}
.navbar .btn {
  /* background-color: rgb(239, 239, 239); */
  background-color: rgb(41, 39, 47);
  padding:0;
  width: 80px;
  height: 33px;
  color: rgba(200,200,200);
  cursor: pointer;
  border-radius: 0px;
  font-size: 1em;
  border: 2px solid rgba(200,200,200);
}
.navbar .btn:hover {
  color: rgb(41, 39, 47);
  background-color: rgba(200,200,200);
}
.navbar .selected {
  color: rgb(41, 39, 47);
  background-color: rgba(200,200,200);
}