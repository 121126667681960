.about{
    /* position: relative; */
    margin: auto;
    width: 100%;
    min-height: 100vh;
}
.about .terminal {
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    margin: 125px auto;
    max-width: 500px;
}
.about .terminal .identity {
    color: var(--heading-color);
}
.about .terminal .division {
    color: var(--primary-color)
}

.banner {
    /* width: 100%; */
    color: white;
    /* background-color: red; */
    background-image:
    linear-gradient(
      to top right, 
      var(--primary-color), rgb(229, 56, 56)
    );
    height: 300px;
    text-align: center;
    padding: 100px;
}
.banner .banner-header{
    font-size: 3rem;
    margin-bottom: 30px;
}

.info {
    padding-bottom: 4rem;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap');
.about .info h1, .about .info h4{
    font-family: 'Roboto Mono', monospace;
    text-align: center;
    margin: auto;
    /* color: rgb(41, 41, 38); */
}
.about .info h4 {
    margin-top: 40px;
}

.about .terminal .term-btn{
    color: var(--heading-color);
    filter: invert(65%);
}
.about-links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    background-color: rgb(41, 39, 47);
    color: rgb(201, 201, 193);
    padding:20px 40px;
    min-height: 4rem;
}
.about-contact{
    text-align: center;
    margin:auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.about-links a{
    color: rgb(201, 201, 193);
}
.about-links a:hover{
    color:white; 
}

.marketing{
    padding:20px;
    margin-bottom: 40px;
}
.horizontal-mark{
    margin-top: auto;
    display: grid;
    gap:20px;
    padding-top: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}
.mark-card{
    position: relative;
    border-radius: 2px;
    border: 2px solid rgb(220, 185, 97);
    border-top: 40px solid rgb(220, 185, 97);
    color: rgb(64, 63, 67);
    /* font-weight: 600; */
    padding: 20px;
    min-height: 180px;
    max-width: 415px;
}
.mark-card p{
    text-align: center;
    background-color: rgba(235,235,235);
    padding: 5px;
    margin-bottom: 10px;
}
.mark-btn{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    border-radius: 20px;
    /* color: white; */
    text-align: center;
    /* background-color: var(--light-color); */
    /* border:2px solid var(--light-color); */
    /* margin: auto, 10%; */
    margin-left: 20%;
    margin-right: 20%;
    padding: 5px;
    margin-top: 20px;
    cursor: pointer;
}
@media (max-width: 850px){
    .horizontal-mark{
        grid-template-columns: 1fr 1fr;
    }
    .about-desc {
        display: none;
    }
    .about-links {
        grid-template-columns: 1fr;
    }
  }
@media (max-width: 540px){
    .about .info h1 {
        font-size:1.8rem;
    }
}
@media (max-width: 415px){
    .about .terminal {
        width:90%
    }
    .horizontal-mark{
        grid-template-columns: 1fr;
        /* gap:0px; */
    }
    .banner{
        padding: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .banner .banner-header {
        font-size:3rem;
    }

    .marketing{
        height: auto;
    }
}