@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap);
/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #6462e9;
  --secondary-color: #9281ea;
  --light-color: #a2a1e6;
  /* --primary-color: #635dff; */
  /* --primary-color: #bba8f0; */
  --red-color: #bba8f0;
  /* --accent-color:#e5c786; */
  --accent-color:#c39022;
  --highlight-color: #ed7b8b;
  --green-color: #9ecb7c;
  --blue-color: #6462e9;
  --dark-blue-color: #5176c6;
  --dark-red-color: #e72b44;;
  --dark-green-color: #7db94f;
  --light-grey-color: rgb(240, 240, 240);
  --yellow-color: #fde483;
  --bg-color: white;
  /* --bg-color: #f4f4f4; */
  /* --bg-color: rgb(247,247,247); */
}
.opaque {
  opacity: 1 !important;
}
.purple{
  color:#6462e9;
  color:var(--primary-color);
}
.red{
  color:#ed7b8b;
  color:var(--highlight-color);
}
.darkred{
  color:#e72b44;
  color:var(--dark-red-color);
}
.gold{
  color:#c39022;
  color:var(--accent-color);
}
.green{
  color:#9ecb7c;
  color:var(--green-color);
}
.blue{
  color:#6462e9;
  color:var(--blue-color);
}
.dark-blue{
  color:#5176c6;
  color:var(--dark-blue-color);
}
.dark-red{
  color:#e72b44;
  color:var(--dark-red-color);
}
.dark-green{
  color:#7db94f;
  color:var(--dark-green-color);
}
.bold {
  font-weight: 600;
}
/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding-top: 48px;
  background: white;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  margin:auto;
  text-align: center;
  width: 100%;
  font-size: 1em;
  color: #444;
  color: var(--heading-color);
  display: inline-block;
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: #a2a1e6;
  color: var(--light-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #a2a1e6;
  border: 1px solid var(--light-color);
}
.btn:hover {
  color: #fff;
  background-color: #a2a1e6;
  background-color: var(--light-color);
}
.danger-btn {
  color: #444;
  color: var(--heading-color);
  border: 1px solid #444;
  border: 1px solid var(--heading-color);
}
.danger-btn:hover {
  color: #fff;
  background-color: #444;
  background-color: var(--heading-color);
}

/* forms */
.auth-form label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.topbuff{
  display: none;
}
textarea {
  min-height: 160px;
}
.error {
  border-radius: 20px;
  padding:5px;
  color: #ed7b8b;
  color: var(--highlight-color);
}
input:focus{
  outline: none !important;
  border: 3px solid #a2a1e6;
  border: 3px solid var(--light-color);
}
.notebook-form input:focus{
  outline: none !important;
  border: 0px solid #a2a1e6;
  border: 0px solid var(--light-color);
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(188, 188, 188);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(188, 188, 188);
  opacity: 1; /* Firefox */
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(188, 188, 188);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(188, 188, 188);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(188, 188, 188);
}
@media (max-width: 415px){
  body{
    font-size: small;
  }
}
@media (max-width: 750px){
  .topbuff{
    display: block;
    min-height: 56px;
  }
}
.App {
  display: block;
  box-sizing: border-box;

}
.App .container {
  flex-grow: 1;
  padding: 0 60px;
}
.content {
  display: flex;
}

div #back {
  position: fixed;
  height: 100px;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  min-height: 100px;
  border-top-left-radius: 250% 260px;
  border-top-right-radius: 250% 260px;
  margin-left: -2em;
  padding-right: 2em;
  z-index: -10;
}

.dashboard {
    width: 100%;
}
.dashboard h2 {
    margin-top: 20px;
}
.notebook-grid{
    margin:20px auto;
    margin-top: 10px;
    display: grid;
    padding: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;
}
.notebook-grid .page-title {
    margin-top: 10px;
}
.notebook-list {
    margin: auto;
    width: 85%;
    margin-top: 25px;
    /* background-color: white; */
    background-color: rgb(249, 248, 245);
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.notebook-list .btns {
    margin-right: auto;
}
.notebook-list .page-title {
    width: auto;
}
.notebook-list h6, .notebook-list h5{
    text-align: center;
}
.notebook-list-item {
    padding: 5px;
    display: grid;
    grid-template-columns: .5fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #ddd;
    min-height: 40px;
}
.notebook-list-item .gridCell{
    position:relative;
}
.notebook-list-item .gridItem{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.notebook-grid-item{
    max-width: 600px;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    background-color: rgb(249, 248, 245);
    /* box-shadow: 3px 3px 5px rgba(0,0,0,0.05); */
}
.notebook-grid-item img, .notebook-list-item img{
    width: 23px;
    height: 23px;
    float: right;
}

.notebook-grid-item h6, .notebook-grid-item h5, .notebook-list-item h6, .notebook-list-item h5, .date {
    color: rgba(0,0,0,0.4);
    margin:0;
}
.date{
    font-size: small;
}
.notebook-grid-item h6, .notebook-list-item h6{
    font-weight: 700;
}
.notebook-grid-item img:hover, .notebook-list-item img:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    cursor:pointer;
}

@media (max-width: 1600px){
    .notebook-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1200px){
    .notebook-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .notebook-list {
        margin: 15px;
        width: auto;
    }
}
@media (max-width: 540px){
    .notebook-grid {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 415px){
    .notebook-grid{
        padding-left: 10px;
        padding-right: 10px;
        /* grid-template-columns: 1fr; */
    }
    .notebook-list {
        margin-left: 10px;
        margin-right: 10px;
    }
    .notebook-grid-item{
        /* height: 100px; */
    }
    .author, .gridDesc{
        display: none;
    }
    .notebook-list-item{
        grid-template-columns: .4fr 1fr 0fr 0fr 1fr;
    }
}
.notebook-buttons{
    display: flex;
    border: 1px solid rgb(210, 210, 210);
    border-top: 0px;
    /* width: 90%; */
    height:39px;
    margin: auto;
}
.top-buttons{
    padding-left: 40px;
    padding-right: 40px;
}
.note-btn-icons {
    box-sizing: border-box;
    display: flex;
    margin-left: auto;
    border: 1px solid black;
    /* border-top: 0px; */
}
.top-buttons .note-btn-icons{
    border-top: 0px;
}
.btn-icon:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    cursor: pointer;
}
.btn-icon, .add-icon{
    padding:5px;
    width: 27px;
    vertical-align: middle;
}

.note-btn-icons .prev{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); 
}
.note-btn-icons .next{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.prev{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.pagenum{
    margin:auto;
}
.modal-container {
    background-color: rgba(0,0,0,0.2);
    position: fixed;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 30;
}
.modal{
    width: 400px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    padding: 10px;
    text-align: center;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
}

.modal p{
    color: var(--text-color)
}
.modal button{
    height: 30px;
    padding: 0;
}

.modal-header {
    /* display: flex; */
    width:100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.modal-header img{
    /* padding: 0; */
    float: right;
    width: 20px;
    height: 20px;
}
.modal img:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    cursor:pointer;
}
.modal-content{
    padding-top: 10px;
}
.modal-buttons  {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
}

@media (max-width: 415px){
    .modal{
        width: 90%;
    }
}
.Generic {
    text-align: center;
    margin: 20px auto;
}


.auth-form {
    max-width: 360px;
    margin: auto;
    margin-top: 50px;
    padding: 40px;
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    background: #fff;
    margin-bottom: 300px;
  }
.auth-form .btn {
    width: 100%
}
.auth-form input {
    width: 347px;
}
.auth-container{
    width: 100%;
}
.auth-reset{
    margin-bottom: 400px;
}
.login-link {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 0px;
    color: var(--heading-color);
    font-size: small;
    cursor: pointer;
}
.auth-form .forgot {
    color: var(--blue-color)
}

@media (max-width: 415px){
    .auth-form {
        padding: 30px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .auth-form input {
        width: 300px;
    }
}
.notebook-container {
    width: 100%;
}
.notebook-container .notebook-title{
    text-align: center;
}
.cell {
    padding-right: 20px;
    padding-left: 20px;
    margin:auto;
    /* margin-bottom: 20px; */
    min-width: 300px;
}
.cell-container{
    display: flex;
}
.cell form {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.cell-buttons{
    width: 97%;
    height: 20px;
    margin: auto;
    display: flex;
}
.cell-buttons img{
    cursor: pointer;
    /* margin-left: 10px; */
    padding-left: 2px;
    height: 25px;
}
.cell-buttons img:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
}
.up-cell img{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

@media (max-width:415px) {
    .cell {
        padding-right: 10px;
        padding-left: 10px;
    }
}
.notebook-header .notebook-form {
    padding: 10px;
}
.notebook-header .notebook-form .btn{
    border-radius: 2px;
}
.optionsmin{
    display: grid;
    grid-template-columns: 1fr 10fr 3fr;
    grid-template-rows: 40px;
    grid-gap: 20px;
    gap: 20px;
}
.optionsmin button{
    border-radius: 0px;
}
.note-form-row {
    display: grid;
    grid-template-columns: 12fr 3fr;
    grid-template-rows: 40px;
    grid-gap: 10px;
    gap: 10px;
}
.note-form-row span {
    line-height: 40px;
    text-align: center;
}
.searchbox{
    margin: 0;
}
.searchbox .searchgrid{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 40px;
    grid-gap:10px;
    gap:10px;
}
@media (max-width: 415px) {
    .optionsmin{
        display: grid;
        grid-template-columns: 1fr 10fr 3fr;
        grid-template-rows: 40px;
        grid-gap: 7px;
        gap: 7px;
    }
}
.notebook-form{
    box-sizing: border-box;
    margin: auto;
    margin-top: 30px;
    width: 100%;
    padding: 20px;
    border-radius: 2px;
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    /* background: #fff; */
    /* background-color: var(--light-grey-color); */
}
.notebook-form .center-label{
    
}
.notebook-form form{
    /* display: flex; */
}
.cell .notebook-form{
    margin-top: 10px;
}
.search-page .notebook-form .btn {
    background-color: #a2a1e6;
    border-color: #a2a1e6;
    padding: 0px;
    color:white;
}
.search-page .notebook-form .btn img{
    -webkit-filter: invert(100%);
            filter: invert(100%);
}
.notebook-form .btn {
    border-radius: 5px;
}
.notebook-header h2{
    margin:auto;
    text-align: center;
}
.notebook-header .options select {
    width: 85px;
    height: 39px;
}
.notebook-header .verse-selection select {
    width: 75px;
}
.center-label {
    margin-top: 0px
}

@media (max-width:415px) {

}

.terminal-list .terminal{
    margin: 0px auto;
    border-radius: 0px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.terminal-list .light-terminal{
    margin: 0px auto;
    border-radius: 0px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}

.note textarea{
    border: 1px solid rgb(211, 211, 211);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    height: 60px;
    min-height: 0px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.05);
    font-weight: 600;
    resize: "none";
    max-width: 100%;
    min-width: 100%;
    overflow:hidden;
}
.note textarea:focus {
    outline: none !important;
    border: 3px solid var(--primary-color);
}

.cell .note{
    padding: 20px;
}
.editorContainer{
    background-color: rgb(240, 240, 240);
    padding:20px;
    border-radius: 5px;
}
.noteTray hr{
    background-color: white;
    color:white;
    border: .5px solid rgb(220, 220, 220);
    /* padding-bottom: 10px; */
}
.noteTray img{
    -webkit-filter: invert(25%);
            filter: invert(25%);
    width: 23px;
    padding:5px;
    cursor: pointer;
}
.noteTray img:hover{
    background-color: rgb(255, 255, 255);
    /* background-color: #ffdb4b; */
    border-radius: 5px;
    cursor: pointer;
}
@media (max-width:415px) {
    .cell .note{
        padding: 10px;
    }
}
.notebook{
    width: 100%;
    /* height: 100vh; */
}
.notebook-title{
    text-align: center;
    font-size: 1.2em;
    color: var(--heading-color);
    width: 100%;
    margin: auto;
    margin-top:20px;
}
.notebook-title input {
    font-weight: 900;
    text-align: center;
    background-color: var(--bg-color);
    color: var(--heading-color);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin-bottom: 10px;
    width: 50%;
}
.notebook-title input:focus{
    border: 3px solid var(--primary-color);
}

.pageList{
    padding-bottom: 45px;
}
.bottomNav{
    background-color: var(--bg-color);
    z-index: 10;
    position: fixed;
    bottom:20px;
    right: 40px;
}
.bottomNav .notebook-buttons{
    width:auto;
    border:0px;
}
.noteDesc{
    padding-right: 20px;
    padding-left: 20px;
}
.preview .notebook-form, .preview .noteTray, .preview .emptyNote{
    display: none;
}


.navbar {
    position: fixed;
    top:0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    /* background-color: rgb(233, 233, 233); */
    background-color: rgb(41, 39, 47);
    z-index: 20;
}
.nav-content{
  margin:auto;
  padding-left: 15px;
  padding-right: 15px;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.navbar .logo {
  font-weight: bold;
  color: rgba(230,230,230);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.navbar .logo img {
  margin-right: 10px;
  -webkit-filter: invert(90%);
          filter: invert(90%);
  width: 36px;
  margin-top: -8px;
}

.navbar .search-icon {
  /* filter: invert(25%); */
  margin: auto;
}
.navbar .search-icon a {
  color: white;
  /* border: 2px solid var(--blue-color); */
  background-color: var(--blue-color);
  border-radius: 20px;
  padding: 3px 5px;
}
.navbar .search-icon a:hover {
  background-color: var(--blue-color);
  color: white;
  font-weight: 600;
}
.navbar a {
  color: rgba(200,200,200);
  text-decoration: none;
  margin-right: 20px;
}
.navbar a:hover {
  color: white;
}
.navbar .btn {
  /* background-color: rgb(239, 239, 239); */
  background-color: rgb(41, 39, 47);
  padding:0;
  width: 80px;
  height: 33px;
  color: rgba(200,200,200);
  cursor: pointer;
  border-radius: 0px;
  font-size: 1em;
  border: 2px solid rgba(200,200,200);
}
.navbar .btn:hover {
  color: rgb(41, 39, 47);
  background-color: rgba(200,200,200);
}
.navbar .selected {
  color: rgb(41, 39, 47);
  background-color: rgba(200,200,200);
}
.sidebar {
  overflow: hidden;
  /* background: rgb(240,240,240); */
  /* background-color: rgb(139, 145, 225); */
  /* background-color: rgb(93, 93, 85); */
  /* border-right: 1px solid rgb(213, 213, 213); */
  background-color: white;
  min-height: calc(100vh - 53px);
  height: 100;
  box-sizing: border-box;
  /* border: 1px solid rgba(0,0,0,.2); */
  border-top: 0px solid rgba(0, 0, 0, 0.05);
  /* color: var(--bg-color); */
  color: var(--heading-color);
  box-sizing: border-box;
}
.thick-sidebar{
  min-width: 200px;
}
.slim-sidebar{
  min-width: 65px;
  max-width: 65px;
}
.sidebar-content {
  position: fixed;
  width: 200px;
}
.sidebar .user {
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 30px 0px;
  padding-bottom: 0;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}

.sidebar .links {
  height: 100%;
  /* margin-top: 45px; */
  padding-top: 20px;
  /* padding-left: 10px; */
  position: fixed;
  border-right: 1px solid rgb(213, 213, 213);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.thick-sidebar .links{
  width: 198px;
}

.slim-sidebar .links{
  width: 63px;
}
.slim-sidebar img{
  padding-bottom: 5px;
}
.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  /* font-size: small; */
  color: var(--heading-color);
  /* color: var(--bg-color); */
  box-sizing: border-box;
}
.sidebar .links img {
  width: 25px;
  height: 25px;
  padding-bottom: 5px;
  padding-left: 5px;
  /* filter: invert(40%); */
  /* filter: invert(100%); */
  -webkit-filter: invert(30%);
          filter: invert(30%);
  margin-right: 10px;
}
.sidebar .links a.active {
  /* color: #555; */
  /* background: var(--bg-color); */
  background: rgba(0,0,0,.065);
}
.sidebar .links .active img {
  /* filter: invert(40%); */
}

.sidebar .links a:hover {
    /* color: #555; */
    background: rgba(0,0,0,.065);
  }
.sidebar .links a:hover img {
    /* filter: invert(40%); */
  }

.collapse {
  border-top: 1px solid rgba(0,0,0,.2);
  cursor: pointer;
  position: fixed; 
  bottom: 0;
  padding: 5px;
  background-color: white;
  width: 188px;
  
}
.sidebar .collapse img{
  float: right;
  justify-content: center;
  -webkit-filter: invert(40%);
          filter: invert(40%);
  width: 40px;
  height: 40px;
}
.thick-sidebar .collapse img{
  padding-right: 15px;
}
.slim-sidebar .collapse img{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.collapse:hover{
  background: rgba(0,0,0,.065);
}
#slideout {
  right: 0px;
  -webkit-animation: slide 0.2s forwards;
  animation: slide 0.2s forwards;
}
.expand{
  background-color: var(--bg-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: fixed;
  bottom: 0;
  padding-bottom:10px;
  padding-left: 5px;
  z-index: 20;
}
.expand img {
  width: 40px;
  -webkit-filter: invert(50%);
          filter: invert(50%);
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.expand img:hover{
  border-radius: 10px;
  background-color: rgba(0,0,0,.1);
}
.sidebar hr {
  background-color: rgba(0,0,0,.05);
  border: 1px solid rgba(0,0,0,.05);
  margin: 10px 0px;
}
#slideout{
  display: none;
  position: fixed;
  left: -200px;
}
.slim-sidebar span{
  display: none;
}

.slim-sidebar .collapse, .slim-sidebar .expand{
  width: 63px;
  padding:0;
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (max-width: 750px){
  .thick-sidebar, .expand {
    display: none; 
  }
}
@media (max-width: 750px){
  .slim-sidebar {
    display: none; 
  }
}
.top-sidebar ul{
  display: flex;
  justify-content: center;
}
.top-sidebar{
  display: none;
  background-color: white;
  z-index: 15;
  position: fixed;
  top:48px;
  left:0;
  right:0;
  border-bottom: 1px solid rgb(213, 213, 213);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.top-sidebar ul li{
  /* margin: auto; */
  padding: 7px;
}
.top-sidebar li:hover{
  background: rgba(0,0,0,.065);
}
.top-sidebar .active-link{
  background: rgba(0,0,0,.065);
}
.top-sidebar .links img {
  padding: 10px;
  /* padding-top: 5px; */
  padding-bottom: 0px;
  width: 25px;
  height: 25px;
  -webkit-filter: invert(30%);
          filter: invert(30%);
}

@media (max-width: 750px){
  .top-sidebar {
    display: block; 
  }
}

@media (max-width: 415px){
  .top-sidebar .links img{
    padding-left: 5px;
    padding-right: 5px;
  }
}


.search-page{
    box-sizing: border-box;
    display:block;
    margin: 0 auto;
    max-width: 750px;
    min-width: 750px;
    padding-left: 15px;
    padding-right: 15px;
}
.notebook-form form{
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-gap:10px;
    gap:10px;
}

.results, .instruction{
    margin: 15px auto;
    color: rgb(77, 77, 77);
}
.results li {
    margin-top: 10px;
    border-radius: 5px;
    padding: 5px;
}
.results li:hover {
    font-weight: 600;
}
.results .route {
    margin-left: 10px;
}
.verse-number{
    font-size: x-small;
    font-weight: 700;
    margin-top: 0px;
    margin:0 2px;
    vertical-align: text-top;
}
.search {
    box-sizing: border-box;
    margin: auto;
    margin-top: 50px;
    width: 500px;
    padding: 40px;
    border: 1px solid #ddd;
    /* border-radius: 5px; */
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    background: #fff;
}
.search-form .btn {
    /* width: 100% */
}

.search-form input {
    width: 100%;
    background-color: white;
    font-weight: 600;
    color: rgba(41, 41, 38, .8);
}
.search-nav {
    display: flex;
    /* padding-top: 20px; */
    margin-top: 10px;
    padding: 5px;
    /* padding-left: 10px;
    padding-right: 10px; */
    justify-content: end;
    border-radius: 5px;
    background-color: rgb(245, 245, 245);
    /* float: right; */
    /* padding: 0px; */
    /* text-align: right; */
}
.search-nav .button {
    cursor: pointer;
    top: 50px;
    /* background-color: rgb(235, 235, 235); */
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    margin-left: 10px;
    border-radius: 5px;
    line-height: 100%;
}
.search-nav .button:hover{
    background-color: rgb(215, 215, 215); 
}
.search-nav img{
    width: 45px;
    -webkit-filter: invert(35%);
            filter: invert(35%);
}
.search-nav .list-toggle-icon, .search-nav .small-search-icon{
    margin:auto;
    margin-left: 0;
}
.small-search-icon img{
    -webkit-filter: invert(100%);
            filter: invert(100%);
}
.small-search-icon{
    background-color: #a2a1e6;
    border-color: #a2a1e6;
}
.search-nav .small-search-icon:hover{
    background-color: #a2a1e6;
}
.options {
    display: grid;
    grid-gap:5px;
    gap:5px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;;
}
.options .search-title {
    /* background-color: black; */
    margin:auto;
    margin-bottom: 15px;
    /* background-color: var(--blue-color); */
    padding: 5px;
    border-radius: 5px;
}
.options .search-title h2{
    margin: auto;
    /* background-color: black; */
    /* color: white */
    color: var(--heading-color)
}

.options select,  .select-box-large{
    width: 115px;
    height: 39px;
}
.select-box-small{
    width: 115px;
    height: 39px;
}
.select-box-med{
    width: 120px;
    height: 39px;
}
.verse-selection {
    display: flex;
}
.verse-selection select {
    width: 50px;
}
.seperator {
    margin: auto 3px;
}

select::-ms-expand { display: none; }
select {
    box-shadow:none;   
    outline:none;      
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url(/static/media/down_arrow.7dd0cb69.svg);
    background-repeat: no-repeat;
    background-position-x: 105%;
    background-position-y: 50%;
    border: 1px solid #838383;
    background-color: var(--bg-color);
    background-size: 20px;
    border-radius: 3px;
    padding: 5px;
    width: 44px;
}
.options label {
    margin-bottom: 10px;
}
.label span {
    margin-bottom: 6px
}
.filter-tag{
    color: var(--blue-color);
    font-size: medium;
}
.searchBar{
    display: flex;
    border: 1px solid rgb(150, 150, 150);
    border-radius: 4px;
}
.searchBar button{
    cursor: pointer;
}
.searchBar:focus{
}
.searchInput{
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    font-weight: 600;
    border:0px;
}
.searchIcon{
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
}
.searchIcon img{
    width: 20px;
    height: 20px;
    background-color: rgb(235, 235, 235);
    border-radius: 5px;
    color: #efefef;
}
.searchIcon img:hover{
    background-color: rgb(215, 215, 215);
}
.search-form-container {

}
@media (max-width:415px) {
    .search{
        width: 350px;
    }
    .notebook-form{
        margin-top: 15px;
        /* width: 360px; */
        padding: 10px;
    }
    .notebook-form form{
        grid-template-columns: 1fr;
    }
    .options select {
        width: 60px;
    }
    .options{
        grid-gap:2px;
        gap:2px;
    }
    .verse-selection select {
        width: 40px;
    }
    .search-page{
        min-width: 200px;
        padding-left: 5px;
        padding-right: 5px;
    }
    /* .notebook-form .options label {
        margin: 5px auto;
    } */
    .select-box-large{
        width: auto;
    }
    .select-box-small{
        width: auto;
    }
    .select-box-med{
        width: auto; 
    }
    .large-search-btn{
        display: none;
    }
}
.text-nav{
    margin: 10px auto;
    width: 500px;
    justify-content: center;
    text-align: right;
    font-weight: 600;
}
.right-arr{
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.left-arr{
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.small-search-icon {
  display: none;
}
@media (max-width: 415px) {
    .text-nav{
        width: 360px;
    }
    .small-search-icon {
      display: block;
    }
    .list-toggle-icon {
      display: none;
    }
}

.terminal, .query-terminal {
    width: 100%;
    margin: 15px auto;
    padding: 20px;
    border-radius: 2px;
    box-sizing: border-box;
  }
.terminal {
  /* background: rgb(41, 41, 38); */
  /* background-color: rgba(240,240,240); */
  /* color: rgb(201, 201, 193); */
  color: var(--heading-color);
  border: 1px solid rgb(181, 181, 181);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
}
.query-terminal {
  border: 2px solid var(--heading-color);
  /* background-color: rgb(255, 255, 255); */
  color: var(--heading-color);
}
.matchWord {
  background-color: var(--yellow-color);
  padding: 2px;
  border-radius: 5px;
  color: var(--heading-color)
  /* color:white; */
}
.light-terminal {
    width: 100%;
    margin: 30px auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    color: var(--heading-color);
    border: 1px solid rgb(211, 211, 211);
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
}
.light-terminal .term-header{
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
}
.terminal-body{
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}
.term-header{
  width: 95%;
  margin: auto;
}
.terminal-content{
  display: flex;
  justify-content: center;
  margin:auto;
}
.light-terminal{
  display: block;
}
.term-side{
  margin: auto 0px;
  height: 30px;
  font-weight: 700;
  align-items: center;
}
.right-arrow {
  cursor: pointer;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.left-arrow {
  cursor: pointer;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.right-arrow:hover {
  /* background-color: var(--primary-color); */
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
}
.left-arrow:hover {
  /* background-color: var(--primary-color); */
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
}
.par-block {
  margin: 10px;
  margin-top:15px;
}

.term-header {
  display: flex;
}

.term-nav {
  display: flex;
  margin-left: auto;
}
.term-btn {
  padding: 2px;
  margin-left: 10px;
  text-align: center;
  cursor: pointer;
  /* background-color: rgb(240,240,240); */
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
  border-radius: 50px;
  line-height: 100%;
}

.term-btn img{
  -webkit-filter: invert(30%);
          filter: invert(30%);
  width: 27px;
}
.term-btn:hover{
  background-color: rgb(220,220,220)
}

.term-btn :hover {
  color: rgba(255,255,255);
}
.query-terminal .term-btn :hover {
  color: var(--accent-color);
}

.divider{
  width: 100%;
  border-color: rgba(255,255,255,.2);

}
.term-center{
  width: 100%;
}
.term-side .term-nav {
  vertical-align: top !important;
}
.indent{
  padding-left: 40px;
}

@media (max-width:415px) {
  .terminal{
    padding-left:10px;
    padding-right:10px;
  }
}
.alert {
    display: flex;
    position: fixed;
    /* top:100px; */
    margin:auto;
    right:0;
    left:0;
    top:105px;
    /* z-index: 10; */
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    width: 250px;
    z-index: 10;
    border-radius: 2px;
    text-align: center;
    /* padding: 3px; */
}
.invisible-alert{
    display: none;
}
.alert p {
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;

}
.success {
    background-color: rgb(162, 225, 160);
}
.warning {
    /* background-color: rgb(245, 255, 159); */
    background-color: rgb(255, 238, 160);
    /* border: 1px solid rgb(167, 172, 117); */
    color: rgb(128, 115, 56);
}
.alert-tag {
    width:10px;
    /* border: 1px solid red; */
}
.alert-icon{
    padding:3px;
    align-self: center;
    color: rgb(207, 186, 93);
}
.warning .alert-tag{
    position: relative;
    background-color: rgb(183, 168, 98);
    color: white;

}
.alert-tag p{
    position: fixed;
    top:50%;
    line-height: 100%;
    /* margin: auto; */
}
.drop-modal .modal-content {
    text-align: left;
    max-height: 400px;
    overflow-y: scroll;
}
.drop-modal .modal-buttons  {
    padding-top: 10px;
    display: flex;
    width: 100%;
}
.drop-modal .btn{
    width: 100%;
}
.drop-modal label {
    /* line-height: 100%; */
    display: grid;
    grid-gap:5px;
    gap:5px;
    grid-template-columns: 1fr 10fr;
    padding: 5px;
}
.drop-modal span{
    margin: 0;
    padding: 2px;
}
.drop-modal input{
    align-self: center;
    height: 18px;
    width: 18px;
}

.odd-row{
    background-color: rgb(240,240,240);
}
.about{
    /* position: relative; */
    margin: auto;
    width: 100%;
    min-height: 100vh;
}
.about .terminal {
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    margin: 125px auto;
    max-width: 500px;
}
.about .terminal .identity {
    color: var(--heading-color);
}
.about .terminal .division {
    color: var(--primary-color)
}

.banner {
    /* width: 100%; */
    color: white;
    /* background-color: red; */
    background-image:
    linear-gradient(
      to top right, 
      var(--primary-color), rgb(229, 56, 56)
    );
    height: 300px;
    text-align: center;
    padding: 100px;
}
.banner .banner-header{
    font-size: 3rem;
    margin-bottom: 30px;
}

.info {
    padding-bottom: 4rem;
}
.about .info h1, .about .info h4{
    font-family: 'Roboto Mono', monospace;
    text-align: center;
    margin: auto;
    /* color: rgb(41, 41, 38); */
}
.about .info h4 {
    margin-top: 40px;
}

.about .terminal .term-btn{
    color: var(--heading-color);
    -webkit-filter: invert(65%);
            filter: invert(65%);
}
.about-links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    background-color: rgb(41, 39, 47);
    color: rgb(201, 201, 193);
    padding:20px 40px;
    min-height: 4rem;
}
.about-contact{
    text-align: center;
    margin:auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.about-links a{
    color: rgb(201, 201, 193);
}
.about-links a:hover{
    color:white; 
}

.marketing{
    padding:20px;
    margin-bottom: 40px;
}
.horizontal-mark{
    margin-top: auto;
    display: grid;
    grid-gap:20px;
    gap:20px;
    padding-top: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}
.mark-card{
    position: relative;
    border-radius: 2px;
    border: 2px solid rgb(220, 185, 97);
    border-top: 40px solid rgb(220, 185, 97);
    color: rgb(64, 63, 67);
    /* font-weight: 600; */
    padding: 20px;
    min-height: 180px;
    max-width: 415px;
}
.mark-card p{
    text-align: center;
    background-color: rgba(235,235,235);
    padding: 5px;
    margin-bottom: 10px;
}
.mark-btn{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    border-radius: 20px;
    /* color: white; */
    text-align: center;
    /* background-color: var(--light-color); */
    /* border:2px solid var(--light-color); */
    /* margin: auto, 10%; */
    margin-left: 20%;
    margin-right: 20%;
    padding: 5px;
    margin-top: 20px;
    cursor: pointer;
}
@media (max-width: 850px){
    .horizontal-mark{
        grid-template-columns: 1fr 1fr;
    }
    .about-desc {
        display: none;
    }
    .about-links {
        grid-template-columns: 1fr;
    }
  }
@media (max-width: 540px){
    .about .info h1 {
        font-size:1.8rem;
    }
}
@media (max-width: 415px){
    .about .terminal {
        width:90%
    }
    .horizontal-mark{
        grid-template-columns: 1fr;
        /* gap:0px; */
    }
    .banner{
        padding: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .banner .banner-header {
        font-size:3rem;
    }

    .marketing{
        height: auto;
    }
}

.settings{
    padding-left: 10px;
    padding-right: 10px;
    margin: 20px auto;
    width: 50%;
    min-width: 300px;
    max-width: 500px;
}
.settings-title{
    margin-bottom: 10px;
}
.settings-content{
    border: 1px solid rgb(181, 181, 181);
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 80px;
}
.settings .btn{
    width: 100%;
}
.settings-content .page-title img{
    width: 25px;
    margin-right: 5px;
}
.settings-form input{
    margin-bottom: 20px;
}
@media (max-width: 415px){
    .settings {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
    .settings-content{
      padding-left: 40px;
      padding-right: 40px;
    }
  }
.info-page {
    background-color: white;
    padding: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: var(--heading-color)
}
.info-title img {
    width: 30px;
    padding-right: 5px;
}
.orange {
    color: rgb(229, 201, 58);
  }
.and {
    color: rgb(229, 84, 58);
}
.description{
    color: rgba(0,0,0,0.5);
    margin: 20px 0px;
}
.top-desc{
    padding-top: 10px;
    padding-bottom: 30px;
}
.info-title{
    margin-top: 10px;
    text-align: center;
}
.info-item-title{
    margin-left: 10px;
}
.info-item-expl {
    padding: 10px;
    padding-top: 5px;
}
.info-item-expl p {
    padding: 5px;
}
.exmpl{
    font-size: small;
    margin: 0 20px;
    margin-top: 10px;
    color: rgba(0,0,0,0.5);
}
.highlighted {
    color: white;
    background-color: var(--light-color);
    padding: 5px;
    font-weight: 600;
}
.info-item-example{
    background-color: rgb(236, 236, 236);
    padding: 20px;
    border-radius: 5px;
    max-width: 438px;
    margin:10px 20px;
    margin-bottom: 30px;
    
}
.info-item-example p{
    padding: 5px;
}

@media (max-width:415px){
    .info-page{
        padding-left: 10px;
        padding-right: 10px;
    }
}

