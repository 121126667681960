.modal-container {
    background-color: rgba(0,0,0,0.2);
    position: fixed;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 30;
}
.modal{
    width: 400px;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 10px;
    text-align: center;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
}

.modal p{
    color: var(--text-color)
}
.modal button{
    height: 30px;
    padding: 0;
}

.modal-header {
    /* display: flex; */
    width:100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.modal-header img{
    /* padding: 0; */
    float: right;
    width: 20px;
    height: 20px;
}
.modal img:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
    cursor:pointer;
}
.modal-content{
    padding-top: 10px;
}
.modal-buttons  {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

@media (max-width: 415px){
    .modal{
        width: 90%;
    }
}