@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #6462e9;
  --secondary-color: #9281ea;
  --light-color: #a2a1e6;
  /* --primary-color: #635dff; */
  /* --primary-color: #bba8f0; */
  --red-color: #bba8f0;
  /* --accent-color:#e5c786; */
  --accent-color:#c39022;
  --highlight-color: #ed7b8b;
  --green-color: #9ecb7c;
  --blue-color: #6462e9;
  --dark-blue-color: #5176c6;
  --dark-red-color: #e72b44;;
  --dark-green-color: #7db94f;
  --light-grey-color: rgb(240, 240, 240);
  --yellow-color: #fde483;
  --bg-color: white;
  /* --bg-color: #f4f4f4; */
  /* --bg-color: rgb(247,247,247); */
}
.opaque {
  opacity: 1 !important;
}
.purple{
  color:var(--primary-color);
}
.red{
  color:var(--highlight-color);
}
.darkred{
  color:var(--dark-red-color);
}
.gold{
  color:var(--accent-color);
}
.green{
  color:var(--green-color);
}
.blue{
  color:var(--blue-color);
}
.dark-blue{
  color:var(--dark-blue-color);
}
.dark-red{
  color:var(--dark-red-color);
}
.dark-green{
  color:var(--dark-green-color);
}
.bold {
  font-weight: 600;
}
/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding-top: 48px;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  margin:auto;
  text-align: center;
  width: 100%;
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--light-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--light-color);
}
.btn:hover {
  color: #fff;
  background-color: var(--light-color);
}
.danger-btn {
  color: var(--heading-color);
  border: 1px solid var(--heading-color);
}
.danger-btn:hover {
  color: #fff;
  background-color: var(--heading-color);
}

/* forms */
.auth-form label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.topbuff{
  display: none;
}
textarea {
  min-height: 160px;
}
.error {
  border-radius: 20px;
  padding:5px;
  color: var(--highlight-color);
}
input:focus{
  outline: none !important;
  border: 3px solid var(--light-color);
}
.notebook-form input:focus{
  outline: none !important;
  border: 0px solid var(--light-color);
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(188, 188, 188);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(188, 188, 188);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(188, 188, 188);
}
@media (max-width: 415px){
  body{
    font-size: small;
  }
}
@media (max-width: 750px){
  .topbuff{
    display: block;
    min-height: 56px;
  }
}