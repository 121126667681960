.notebook{
    width: 100%;
    /* height: 100vh; */
}
.notebook-title{
    text-align: center;
    font-size: 1.2em;
    color: var(--heading-color);
    width: 100%;
    margin: auto;
    margin-top:20px;
}
.notebook-title input {
    font-weight: 900;
    text-align: center;
    background-color: var(--bg-color);
    color: var(--heading-color);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin-bottom: 10px;
    width: 50%;
}
.notebook-title input:focus{
    border: 3px solid var(--primary-color);
}

.pageList{
    padding-bottom: 45px;
}
.bottomNav{
    background-color: var(--bg-color);
    z-index: 10;
    position: fixed;
    bottom:20px;
    right: 40px;
}
.bottomNav .notebook-buttons{
    width:auto;
    border:0px;
}
.noteDesc{
    padding-right: 20px;
    padding-left: 20px;
}
.preview .notebook-form, .preview .noteTray, .preview .emptyNote{
    display: none;
}