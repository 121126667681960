.info-page {
    background-color: white;
    padding: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: var(--heading-color)
}
.info-title img {
    width: 30px;
    padding-right: 5px;
}
.orange {
    color: rgb(229, 201, 58);
  }
.and {
    color: rgb(229, 84, 58);
}
.description{
    color: rgba(0,0,0,0.5);
    margin: 20px 0px;
}
.top-desc{
    padding-top: 10px;
    padding-bottom: 30px;
}
.info-title{
    margin-top: 10px;
    text-align: center;
}
.info-item-title{
    margin-left: 10px;
}
.info-item-expl {
    padding: 10px;
    padding-top: 5px;
}
.info-item-expl p {
    padding: 5px;
}
.exmpl{
    font-size: small;
    margin: 0 20px;
    margin-top: 10px;
    color: rgba(0,0,0,0.5);
}
.highlighted {
    color: white;
    background-color: var(--light-color);
    padding: 5px;
    font-weight: 600;
}
.info-item-example{
    background-color: rgb(236, 236, 236);
    padding: 20px;
    border-radius: 5px;
    max-width: 438px;
    margin:10px 20px;
    margin-bottom: 30px;
    
}
.info-item-example p{
    padding: 5px;
}

@media (max-width:415px){
    .info-page{
        padding-left: 10px;
        padding-right: 10px;
    }
}