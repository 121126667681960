.notebook-header .notebook-form {
    padding: 10px;
}
.notebook-header .notebook-form .btn{
    border-radius: 2px;
}
.optionsmin{
    display: grid;
    grid-template-columns: 1fr 10fr 3fr;
    grid-template-rows: 40px;
    gap: 20px;
}
.optionsmin button{
    border-radius: 0px;
}
.note-form-row {
    display: grid;
    grid-template-columns: 12fr 3fr;
    grid-template-rows: 40px;
    gap: 10px;
}
.note-form-row span {
    line-height: 40px;
    text-align: center;
}
.searchbox{
    margin: 0;
}
.searchbox .searchgrid{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 40px;
    gap:10px;
}
@media (max-width: 415px) {
    .optionsmin{
        display: grid;
        grid-template-columns: 1fr 10fr 3fr;
        grid-template-rows: 40px;
        gap: 7px;
    }
}