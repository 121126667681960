
.search-page{
    box-sizing: border-box;
    display:block;
    margin: 0 auto;
    max-width: 750px;
    min-width: 750px;
    padding-left: 15px;
    padding-right: 15px;
}
.notebook-form form{
    display: grid;
    grid-template-columns: 9fr 1fr;
    gap:10px;
}

.results, .instruction{
    margin: 15px auto;
    color: rgb(77, 77, 77);
}
.results li {
    margin-top: 10px;
    border-radius: 5px;
    padding: 5px;
}
.results li:hover {
    font-weight: 600;
}
.results .route {
    margin-left: 10px;
}
.verse-number{
    font-size: x-small;
    font-weight: 700;
    margin-top: 0px;
    margin:0 2px;
    vertical-align: text-top;
}
.search {
    box-sizing: border-box;
    margin: auto;
    margin-top: 50px;
    width: 500px;
    padding: 40px;
    border: 1px solid #ddd;
    /* border-radius: 5px; */
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    background: #fff;
}
.search-form .btn {
    /* width: 100% */
}

.search-form input {
    width: 100%;
    background-color: white;
    font-weight: 600;
    color: rgba(41, 41, 38, .8);
}
.search-nav {
    display: flex;
    /* padding-top: 20px; */
    margin-top: 10px;
    padding: 5px;
    /* padding-left: 10px;
    padding-right: 10px; */
    justify-content: end;
    border-radius: 5px;
    background-color: rgb(245, 245, 245);
    /* float: right; */
    /* padding: 0px; */
    /* text-align: right; */
}
.search-nav .button {
    cursor: pointer;
    top: 50px;
    /* background-color: rgb(235, 235, 235); */
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    margin-left: 10px;
    border-radius: 5px;
    line-height: 100%;
}
.search-nav .button:hover{
    background-color: rgb(215, 215, 215); 
}
.search-nav img{
    width: 45px;
    filter: invert(35%);
}
.search-nav .list-toggle-icon, .search-nav .small-search-icon{
    margin:auto;
    margin-left: 0;
}
.small-search-icon img{
    filter: invert(100%);
}
.small-search-icon{
    background-color: #a2a1e6;
    border-color: #a2a1e6;
}
.search-nav .small-search-icon:hover{
    background-color: #a2a1e6;
}
.options {
    display: grid;
    gap:5px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;;
}
.options .search-title {
    /* background-color: black; */
    margin:auto;
    margin-bottom: 15px;
    /* background-color: var(--blue-color); */
    padding: 5px;
    border-radius: 5px;
}
.options .search-title h2{
    margin: auto;
    /* background-color: black; */
    /* color: white */
    color: var(--heading-color)
}

.options select,  .select-box-large{
    width: 115px;
    height: 39px;
}
.select-box-small{
    width: 115px;
    height: 39px;
}
.select-box-med{
    width: 120px;
    height: 39px;
}
.verse-selection {
    display: flex;
}
.verse-selection select {
    width: 50px;
}
.seperator {
    margin: auto 3px;
}

select::-ms-expand { display: none; }
select {
    box-shadow:none;   
    outline:none;      
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('../../assets/down_arrow.svg');
    background-repeat: no-repeat;
    background-position-x: 105%;
    background-position-y: 50%;
    border: 1px solid #838383;
    background-color: var(--bg-color);
    background-size: 20px;
    border-radius: 3px;
    padding: 5px;
    width: 44px;
}
.options label {
    margin-bottom: 10px;
}
.label span {
    margin-bottom: 6px
}
.filter-tag{
    color: var(--blue-color);
    font-size: medium;
}
.searchBar{
    display: flex;
    border: 1px solid rgb(150, 150, 150);
    border-radius: 4px;
}
.searchBar button{
    cursor: pointer;
}
.searchBar:focus{
}
.searchInput{
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    font-weight: 600;
    border:0px;
}
.searchIcon{
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
}
.searchIcon img{
    width: 20px;
    height: 20px;
    background-color: rgb(235, 235, 235);
    border-radius: 5px;
    color: #efefef;
}
.searchIcon img:hover{
    background-color: rgb(215, 215, 215);
}
.search-form-container {

}
@media (max-width:415px) {
    .search{
        width: 350px;
    }
    .notebook-form{
        margin-top: 15px;
        /* width: 360px; */
        padding: 10px;
    }
    .notebook-form form{
        grid-template-columns: 1fr;
    }
    .options select {
        width: 60px;
    }
    .options{
        gap:2px;
    }
    .verse-selection select {
        width: 40px;
    }
    .search-page{
        min-width: 200px;
        padding-left: 5px;
        padding-right: 5px;
    }
    /* .notebook-form .options label {
        margin: 5px auto;
    } */
    .select-box-large{
        width: auto;
    }
    .select-box-small{
        width: auto;
    }
    .select-box-med{
        width: auto; 
    }
    .large-search-btn{
        display: none;
    }
}