.cell {
    padding-right: 20px;
    padding-left: 20px;
    margin:auto;
    /* margin-bottom: 20px; */
    min-width: 300px;
}
.cell-container{
    display: flex;
}
.cell form {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.cell-buttons{
    width: 97%;
    height: 20px;
    margin: auto;
    display: flex;
}
.cell-buttons img{
    cursor: pointer;
    /* margin-left: 10px; */
    padding-left: 2px;
    height: 25px;
}
.cell-buttons img:hover{
    background-color: rgb(225, 225, 225);
    border-radius: 5px;
}
.up-cell img{
    transform: rotate(180deg);
}

@media (max-width:415px) {
    .cell {
        padding-right: 10px;
        padding-left: 10px;
    }
}